.App {
  margin-top: 16px;
  overflow: hidden;
}

.appContent {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  min-height: calc(50vh + 100px);
}

.logoContainer {
  max-width: 300px;

  @media (max-width: 800px) {
    margin: 0 auto;
  }
}

.logo {
  width: 100%;
}

.circle {
  display: flex;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
}

.queueNumberTitle,
.queueNumber {
  font-size: 2.7rem;
  text-align: center;
  margin: 0;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.queueNumber {
  background-color: #e9edc9;
  padding: 30px;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 12px;
}

.extraInfoContainer {
  max-width: 600px;
  padding-bottom: 100px;
  text-align: center;
}

.extraInfoHeading {
  margin: 0;
}

.introText {
  font-style: italic !important;
  margin: 12px;
}

a {
  color: #da1f27;
}

.spinner {
  animation-name: spin;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}