.btn { 
    background: #da1f27 !important;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 36px;
    letter-spacing: -0.6px;
    line-height: 30px;
    min-width: 120px;
    padding: 16px 36px;
    text-decoration: none;
    user-select: none;
    white-space: normal;

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:disabled {
        background: #999 !important;
    }

    &::after {
        display: none !important;
    }
}

.spinner {
    animation-name: spin;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }